"use strict";

const modalSlide = function (){
    let modalSlideOpenFlag = false; // определяет, был ли открыт попап хотя бы 1 раз

    // Если попап еще ни разу не был открыт (нет куки modal_slide_show)
    try {
        if( !Cookies.get('modal_slide_show') ){
            setTimeout(function(){
                // И если попап не открывали вручную и в данный момент не открыто никакого другого попапа
                if (!modalSlideOpenFlag && !document.documentElement.classList.contains('with-fancybox')){
                    modalSlideOpenFlag = true;

                    // То открываем этот попап (спустя 30 сек нахождения на странице)
                    // (опять же, если нет куки modal_slide_show)
                    // Проверяем куку для того, чтобы попап не открывался на каждой странице сайта,
                    // если было открыто несколько вкладок.
                    if( !Cookies.get('modal_slide_show') ){
                        try {
                            new Fancybox(
                                [{
                                    src : '/modal_slide/',
                                    type : 'ajax'
                                }]
                            );
                        } catch (err) {
                            console.log(err);
                        }
                    }

                    // и стаим куку на сутки, показывающую что попап был открыт
                    Cookies.set('modal_slide_show', 'true', { expires: 1 });
                }
            },30000);
        }
    } catch (err) {
        console.log(err);
    }

}
modalSlide();